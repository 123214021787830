import { render, staticRenderFns } from "./planRegulatory.vue?vue&type=template&id=2819313b&scoped=true&"
import script from "./planRegulatory.vue?vue&type=script&lang=js&"
export * from "./planRegulatory.vue?vue&type=script&lang=js&"
import style0 from "./planRegulatory.vue?vue&type=style&index=0&id=2819313b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2819313b",
  null
  
)

export default component.exports