// 施工阶段照片监管   月自评表监管  中止/复工申请  项目概况监管  申报方案监管
import { request } from "./request"

// 月自评表监管
// 项目添加评价
export function addScore(params) {
    return request({
        url: "api/yue_c/",
        method: "post",
        data: params
    })

}

// 获取项目评价
export function getScore(params) {
    return request({
        url: "api/yue_c/",
        method: "get",
        params: params
    })

}

// 项目概况监管
// 获取项目信息
export function getProjectRegulatory(params) {
    return request({
        url: "v3/023/",
        method: "get",
        params: params
    })
}

// 添加项目监管图片
export function addRegulatoryPicture(params) {
    return request({
        url: "api/sup_a/",
        method: "post",
        data: params
    })
}

// 添加项目监管图片
export function getRegulatoryPicture(params) {
    return request({
        url: "api/sup_a/",
        method: "get",
        params: params
    })
}

// 申报方案监管
// 获取申报方案
export function getDeclare(params) {
    return request({
        url: "v3/024/",
        method: "get",
        params: params
    })
}

// 添加申报方案
export function addDeclare(params) {
    return request({
        url: "api/sch_e/",
        method: "post",
        data: params
    })
}